import React from "react";
import { Bem } from "../../utils/bem";
import "./styles.scss";

interface Props {
  content: string;
}

const component = new Bem("c-coming-soon");

const ComingSoon = ({ content }: Props) => {
  return (
    <div className={component.with()}>
      <p className={component.with("content")}>{content}</p>
    </div>
  );
};

export default ComingSoon;
