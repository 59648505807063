import React from "react";
import ComingSoon from "../ComingSoon";
import Quote from "../Quote";
import scrollTo from "../../utils/scrollTo";
import { Bem } from "../../utils/bem";
import "./styles.scss";

interface Props {
  components?: any[];
  title: string;
  alt?: boolean;
  fullSize?: boolean;
  index?: number;
  html?: string;
  autoScroll?: string;
}

type ListItem = string[] | string;

const component = new Bem("l-section");

const Section = ({ title, components, alt, fullSize, html, index, autoScroll}: Props) => {
  const renderContents = (content: any, contentIndex: number) => {
    if (content.component === "paragraph") {
      return (
        <p key={`${index}-${contentIndex}-paragraph`} className={component.with("paragraph")}>
          {content.content}
        </p>
      );
    } else if (content.component === "coming-soon") {
      return (
        <ComingSoon key={`${index}-${contentIndex}-coming-soon`} content={content.content} />
      );
    } else if (content.component === "quote") {
      return (
        <Quote key={`${index}-${contentIndex}-quote`} quote={content.content.quote} source={content.content.source} />
      );
    } else if (content.component === "list") {
      return (
        <ul key={`${index}-${contentIndex}-list`} className="l-section-list">
          {
            content.content.list.map((item: ListItem, listIndex: number) => {
              if (Array.isArray(item)) {
                return (
                  <ul className="l-section-nested-list">
                    {item.map((nestedItem: string, nestedListIndex: number) => {
                      return (
                        <li
                          key={`${index}-${contentIndex}-list-${listIndex}-${nestedListIndex}`}
                          className="l-section-nested-list-item"
                        >
                          {nestedItem}
                        </li>
                      );
                    })}
                  </ul>
                );
              } else {
                return (
                  <li
                    key={`${index}-${contentIndex}-list-${listIndex}`}
                    className="l-section-list__item"
                  >
                    {item}
                  </li>
                );
              }
            })
          }
        </ul>
      );
    } else if (content.component === "sub-heading") {
      return (
        <h3 key={`${index}-${contentIndex}-sub-heading`} className="l-section__subtitle">{content.content}</h3>
      );
    } else if (content.component === "iframe") {
      return (
        <iframe
          key={`${index}-${contentIndex}-iframe`}
          width="100%"
          height="300"
          src={content.content}></iframe>
      );
    }
  };

  const autoScrollTo = () => {
    const element = document.getElementById(autoScroll);
    scrollTo(element);
  };

  return (
    <article className={component.with("", { alt, "full-size": fullSize })} id={`section-${index}`}>
      <h2 className={component.with("title")}>{title}</h2>
      {components && components.map(renderContents)}
      {html && <div className={component.with("html")} dangerouslySetInnerHTML={{ __html: html }} />}
      {autoScroll && <button className={component.with("next-section")} onClick={autoScrollTo}>»</button>}
    </article>
  );
};

export default Section;
