import React from "react";
import { Bem } from "../../utils/bem";
import "./styles.scss";

interface Props {
  quote: string;
  source: {
    author: string;
    link: string;
    linkLabel: string;
  };
}

const component = new Bem("c-quote");

const Quote = ({ quote, source }: Props) => {
  return (
    <div className={component.with()}>
      <p className={component.with("message")}>
        {quote}
      </p>
      <p className={component.with("source")}>
        {source.author} <a href={source.link}>{source.linkLabel}</a>
      </p>
    </div>
  );
};

export default Quote;
