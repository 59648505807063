import React from "react";
import { Bem } from "../../utils/bem";
import "./styles.scss";

interface Props {
  center?: boolean;
  title: string;
  message?: string;
}

const component = new Bem("c-talk-footer");

const TalkFooter = ({ title, message, center }: Props) => {
  return (
    <div className={component.with("", ["align"])} id="talk-footer">
      <div className={component.with("wrapper", { center })}>
        <h2 className={component.with("title")}>{title}</h2>
        <h3 className={component.with("message")}>{message}</h3>
      </div>
    </div>
  );
};

export default TalkFooter;
