import { Link } from "gatsby";
import React from "react";
import { Bem } from "../../utils/bem";
import "./styles.scss";

interface Props {
  alt?: boolean;
  tags: {
    tags: string[];
    slugPrefix: string;
  };
}

const component = new Bem("c-tag-footer");
const tagComponent = new Bem("c-tag");

const TagFooter = ({ alt, tags }: Props) => {
  const renderTags = tags.tags.map((tag: any) => {
    return (
      <Link
        key={`tags-${tag}`}
        className={tagComponent.with("", { alt })}
        to={`${tags.slugPrefix}/${tag}/`}
      >
        {tag}
      </Link>
    );
  });

  return (
    <div className={component.with("", { alt })}>
      {renderTags}
    </div>
  );
};

export default TagFooter;
