import * as React from "react";
import { Link } from "gatsby";
import { get } from "lodash";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Container, Segment, Icon, Label, Button, Grid, Card, Image, Item, Comment } from "semantic-ui-react";
import { MarkdownRemark, ImageSharp, MarkdownRemarkConnection, Site } from "../graphql-types";
import Header from "../components/Header";
import HeaderMenu from "../components/HeaderMenu";
import TagFooter from "../components/TagFooter";
import TalkFooter from "../components/TalkFooter";
import Section from "../components/Section";
import BlogTitle from "../components/BlogTitle";
import { DiscussionEmbed } from "disqus-react";
import {withLayout, LayoutProps, menuItems} from "../components/Layout";
import { graphql } from "gatsby";

interface TalkPostProps extends LayoutProps {
  data: {
    talk: MarkdownRemark;
    recents: MarkdownRemarkConnection;
    site: Site
  };
}

const TalkPostPage = (props: TalkPostProps) => {
  const { frontmatter, timeToRead, body } = props.data.talk;

  const recents = props.data.recents.edges
    .map(({ node }) => {

      return (
        <div key={node.fields.slug} style={{ paddingBottom: "1em" }}>
          <Card as={Link}
            to={node.fields.slug}
            header={node.frontmatter.title}
          />
        </div>
      );
    });

  const shortcodes = { Section, TalkFooter };
  return (
    <React.Fragment>
      <HeaderMenu pathname={props.location.pathname} items={menuItems} />
      <Header title={frontmatter.title} strapline={frontmatter.strapline} autoScroll />
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{body}</MDXRenderer>
      </MDXProvider>
      <TagFooter tags={{ slugPrefix: "", tags: props.data.talk.frontmatter.tags }} />
      <Segment vertical>
        <Grid padded centered>
          {recents}
        </Grid>
      </Segment>
    </React.Fragment>
  );
};

export default withLayout(TalkPostPage);

export const pageQuery = graphql`
  query TemplateTalk($slug: String!) {
  talk: mdx(fields: {slug: {eq: $slug}}) {
    body
    excerpt
    timeToRead
    fields {
      slug
    }
    frontmatter {
      tags
      title
      strapline
      updatedDate(formatString: "MMM D, YYYY")
    }
  }
  recents: allMdx(
    filter: {
      fields: {slug: {ne: $slug}}
      frontmatter: {draft: {ne: true}},
      fileAbsolutePath: {regex: "/blog/"},
    },
    sort: {order: DESC, fields: [frontmatter___updatedDate]},
    limit: 4
  ) {
    edges {
      node {
        fields {
          slug
        }
        timeToRead
        frontmatter {
          title
          strapline
        }
      }
    }
  }
}
`;
